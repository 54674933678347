import Vue from 'vue'
import Vuex from 'vuex'
import {fileInfoModule} from './fileInfoModule'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user : {
      name: "WSCAD"
    }
  },
  getters: {
    getUserTypes(){
      return {
        regularUser : 0,
        companyAdmin : 1,
        systemAdmin : 2
      }
    },
    getUser()
    {
      let user = localStorage.getItem("user");
      if(user != undefined && user != "undefined" && user != null && user != "")
        return JSON.parse(user);
      return null;
    }
    //state props will be returned
  },
  mutations: {
    //action retrieve and state update will be there
    
  },
  actions: {
    //api calls will be there
    
  },
  modules: {
    fileInfoModule
  }
})
