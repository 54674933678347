<template>
  <v-app>
    <v-app-bar
      app
      color="wscad-title"
      dark
    >
      <router-link :to="{name:'FileInfoList'}" class="d-flex align-center">

        <v-img
          alt="Vuetify Name"
          class="shrink mt-1 hidden-sm-and-down"
          contain
          min-width="100"
          src="https://www.wscad.com/wp-content/uploads/2022/05/WSCAD_Logo_Claim_negativ_web_nobevel.svg"
          width="100"
        />
      </router-link >

      <v-spacer></v-spacer>

      <v-btn
        @click="logOut()"
        text
      >
        <span class="mr-2">Log Off</span>
        <v-icon>mdi-open-in-new</v-icon>
      </v-btn>
    </v-app-bar>

    <v-main>
      <router-view>

      </router-view>
      <!-- <FileInfoList/> -->
      <v-container>
          © 2024 - WSCAD - Digiservices (v.{{version_tag}})
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
// import FileInfoList from './components/FileInfoList';
import axios from "axios";
import SecurityService from "./authentication/SecurityService";
let securityService = new SecurityService();

export default {
  name: 'App',

  components: {
    // FileInfoList, 
    // Next
  },

  data() {
    return {
      version_tag: process.env.VUE_APP_VERSION_TAG
    }
  },

  created () {
    axios.defaults.headers.common['Cache-Control'] = 'no-cache';
    axios.defaults.headers.common['Pragma'] = 'no-cache';
    axios.defaults.headers.common['Expires'] = '0';
    axios.interceptors.response.use(response => {
      return response;
    }, error => {
      if (error.response.status === 401) {
        localStorage.removeItem("token");
        securityService.authenticate();
      }
      return error;
    });
    this.setSearchString();
    securityService.authenticate();
    let itemsToBeDeleted = localStorage.getItem('itemsTobeDeleted')
    if(itemsToBeDeleted == null)
          {
            itemsToBeDeleted = {}
            localStorage.setItem('itemsTobeDeleted', JSON.stringify(itemsToBeDeleted))
          }
  },
  mounted () {
    console.log("We are here VUE_APP_API_URL:" + process.env.VUE_APP_API_URL)
    console.log("--> VUE_APP_VERSION_TAG:" + process.env.VUE_APP_VERSION_TAG)
    this.version_tag = process.env.VUE_APP_VERSION_TAG
  },
    methods: {
      setSearchString(){
        let uri = window.location.href.split('?');
        let that = this;
        if(uri.length == 2) {
          let vars = uri[1].split('&');
          let tmp = '';
          if(vars.length == 1)
          {
            vars.forEach(function(v) {
              tmp = v.split('=');
              if(tmp.length == 2 && tmp[0] == "SearchString")
              {
                that.setCookie("SearchString", tmp[1],1)
              }
            });
          }
        }
      },
      setCookie(name,value,days) {
          var expires = "";
          if (days) {
              var date = new Date();
              date.setTime(date.getTime() + (days*24*60*60*1000));
              expires = "; expires=" + date.toUTCString();
          }
          document.cookie = name + "=" + (value || "")  + expires + "; path=/";
      },
      logOut() {
        securityService.logOut();
      }
    },
};
</script>
<style>

  .v-data-table-header th.sortable{
    white-space: nowrap;
  }
  .wscad-title {
    background: linear-gradient(to bottom,#10263b 0%,#144d83 100%)
  }
</style>