/* eslint-disable */
import Oidc from 'oidc-client';
import axios from 'axios';

//Staging
//let clientId = '7ur0uro27ngbn69sio3ca2r7m0';
//let authority = 'https://auth.wscad-service.com/';

//Prod
let clientId = '5pbkkfqe9i93k765srep106klo';
let authority = 'https://auth.wscaduniverse.com/';

var mgr = new Oidc.UserManager({
  userStore: new Oidc.WebStorageStateStore({ store: window.localStorage }),
  authority: authority,
  client_id: clientId,
  redirect_uri: window.location.origin + '/callback',
  response_type: 'code',
  scope: 'openid',
  post_logout_redirect_uri: window.location.origin,
  filterProtocolClaims: true
})

Oidc.Log.logger = console;
Oidc.Log.level = Oidc.Log.INFO;

mgr.events.addUserLoaded(function (user) {  
  console.log('New User Loaded：', arguments);
  console.log('Acess_token: ', user.access_token)
});

mgr.events.addAccessTokenExpiring(function () {
  console.log('AccessToken Expiring：', arguments);
});

mgr.events.addAccessTokenExpired(function () {
  console.log('AccessToken Expired：', arguments);  
  alert('Session expired. Going out!');
  mgr.signoutRedirect().then(function (resp) {
    console.log('signed out', resp);
  }).catch(function (err) {
    console.log(err)
  })
});

mgr.events.addSilentRenewError(function () {
  console.error('Silent Renew Error：', arguments);
});

mgr.events.addUserSignedOut(function () {
  alert('Going out!');
  console.log('UserSignedOut：', arguments);  
  mgr.signoutRedirect().then(function (resp) {
    console.log('signed out', resp);
  }).catch(function (err) {
    console.log(err)
  })
});

export default class SecurityService {

  // Renew the token manually
  getQueryParam (paramName) {
    let paramValue = "";
    let uri = window.location.href.split('?');
    if(uri.length == 2) {
      let vars = uri[1].split('&');
      let tmp = '';
      vars.forEach(function(v) {
        tmp = v.split('=');
        if(tmp.length == 2 && tmp[0] == paramName)
        {
            paramValue = tmp[1]
        }
      });
    }
    return paramValue;
  }
  logOut(){
    let token = localStorage.getItem("token")
    for (let key in localStorage) {
      if (key !== 'visibleHeaders' && key !== 'headersOrder') {
          localStorage.removeItem(key);
      }
  }
    window.location = `${authority}oauth2/logout?access_token=${token}&post_logout_redirect_uri=${window.location.origin}`
  }
  getToken () {
    let state = this.getQueryParam("state");
    let code = this.getQueryParam("code");
    let userObject = localStorage.getItem("oidc." + state)
    if(userObject != null)
    {
        userObject = JSON.parse(userObject)
        axios.post(`${authority}oauth2/token`,
                {
                    grant_type: 'authorization_code',
                    client_id: clientId,
                    redirect_uri: window.location.origin,
                    code_verifier: userObject.code_verifier,
                    code: code
                },
                { headers: { 'Content-Type': 'application/json-patch+json', 'accept': 'text/plain'}, }
              ).then((response) => {
                axios.defaults.headers.common['Authorization'] = 'Bearer ' + response.data.access_token;
                localStorage.setItem('token', response.data.access_token)
                axios
                .get(`${process.env.VUE_APP_API_URL}Authentication/GetUser?info=${response.data.id_token}`)
                .then(response => {
                  localStorage.setItem("user",JSON.stringify(response.data));
                  window.location = window.location.origin;
                })
              }).catch((error) => {
                this.signIn()
                console.log(error);
              })
    }
  }

  // Redirect of the current window to the authorization endpoint.
  signIn () {

    mgr.signinRedirect().catch(function (err) {
      console.log(err)
    })
  }

  // Get the access token of the logged in user
  authenticate(){
    let token = localStorage.getItem("token")
    if(token != null)
    {
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
        return token;
    }
    let state = this.getQueryParam("state");
    if(state != null && state != '')
    {
        return state;
    }
    this.signIn()
    return null;
  }


}