import axios from "axios";

export const fileInfoModule = {
    state: {
        activeFileInfo: {},
        fileInfoList: [
            
          ],
        itemsToBeDeleted : {}
      },
      getters: {
        getActiveFileinfo(state)
        {
          return state.activeFileInfo;
        },
        getTobeDeletedItems(state)
        {
          return state.itemsToBeDeleted;
        },
        getFileInfoList(state)
        {
          return state.fileInfoList;
        },

        eraseCookie(name) {   
            document.cookie = name +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
        }
        //state props will be returned
      },
      mutations: {
        //action retrieve and state update will be there
        setItemsToBeDeleted(state)
        {
          let itemsToBeDeleted = JSON.parse(localStorage.getItem('itemsTobeDeleted'))
          state.itemsToBeDeleted = itemsToBeDeleted;
        },
        setFileInfoList(state, payload)
        {
          let fileInfoList = payload.data.data;
          let itemsToBeDeleted = JSON.parse(localStorage.getItem('itemsTobeDeleted'))
          for(let i = 0; i < fileInfoList.length; i++)
          {
            if(fileInfoList[i].externalAdminId != 0)
              fileInfoList[i].informExternalAdmin = true
            else
              fileInfoList[i].informExternalAdmin = false

            if(itemsToBeDeleted[fileInfoList[i].requestId] != undefined)
            {
              fileInfoList[i].tobeDeleted = true;
            }
            else
            {
              fileInfoList[i].tobeDeleted = false;
            }
          }
          state.fileInfoList = fileInfoList;
          localStorage.setItem('itemsTobeDeleted', JSON.stringify(itemsToBeDeleted))
          state.itemsToBeDeleted = itemsToBeDeleted
        },
        setActiveFileInfo(state, payload)
        {
          state.activeFileInfo = payload;
        }
      },
      actions: {
        //api calls will be there
        updateActiveFileInfo(context, patchRequest)
        {
          console.log(context);
          axios.patch(`${process.env.VUE_APP_API_URL}FileInfo/${patchRequest.id}`,
            patchRequest.data,
            { headers: { 'Content-Type': 'application/json-patch+json', 'accept': 'text/plain'}, }
          ).then((response) => {
            if(patchRequest.callback != undefined)
              patchRequest.callback(response);
          }).catch((error) => {
            console.log(error);
          })
        },
        deleteActiveFileInfo(context, deleteRequest)
        {
          console.log(context);
          axios.delete(`${process.env.VUE_APP_API_URL}FileInfo/${deleteRequest.id}`)
          .then(response => {
            console.log(response);
            if(deleteRequest.callback != undefined)
              deleteRequest.callback(response);
          }).catch(error => {
            console.log(error);
          });
        },
        getFileInfoPaged(context, filter){
            let pageSize = filter.PageSize == -1 ? 2147483647 : filter.PageSize; 
            axios
                .get(`${process.env.VUE_APP_API_URL}FileInfo?Page=${filter.Page}&PageSize=${pageSize}&SortOrder=${filter.SortOrder}&SortDirection=${filter.SortDirection}&SearchString=${encodeURIComponent(filter.SearchString)}&status=${filter.status == -1 ? '' : filter.status}`)
                .then(response => {
                  if(response.data != undefined && response.data.data != undefined)
                  {
                    fileInfoModule.getters.eraseCookie("SearchString");
                    (context.commit('setFileInfoList', response));
                    if(filter.callback != undefined)
                      filter.callback(response);
                  }
              })
        },
        deleteRequests(context, deleteRequest)
        {
          console.log(context);
          let requests = deleteRequest.requests;
          let ids = []
          let itemsToBeDeleted = JSON.parse(localStorage.getItem('itemsTobeDeleted'))
          for(let i = 0; i < requests.length; i++){
            ids.push(itemsToBeDeleted[requests[i]]);
          }
          
          axios.delete(`${process.env.VUE_APP_API_URL}FileInfo/RemoveMultiple`, 
          { 
            headers: { 'Content-Type': 'application/json-patch+json' }, 
            data: ids } 
          ).then(response => {
            console.log(response);
            if(deleteRequest.callback != undefined)
            {  
              deleteRequest.callback();
            }
            localStorage.setItem('itemsTobeDeleted', JSON.stringify({}))
          }).catch(error => {
            console.log(error);
          });
        },
        setTobeDeleted(context, item){
          console.log(context);
          let itemsToBeDeleted = JSON.parse(localStorage.getItem('itemsTobeDeleted'))
          if(item != undefined)
          {
            if(item.tobeDeleted == true)
            {
              itemsToBeDeleted[item.requestId] = item.id; 
              item.tobeDeleted = true;
            }
            else{
    
              itemsToBeDeleted[item.requestId] = undefined; 
              item.tobeDeleted = false;
            }
          }
          localStorage.setItem('itemsTobeDeleted', JSON.stringify(itemsToBeDeleted))
          context.commit('setItemsToBeDeleted', itemsToBeDeleted);

        }
      }
      
}