import Vue from 'vue'
import VueRouter from 'vue-router'
//import FileInfoListPage from '../components/FileInfoListPage';
// import LoginPage from '../components/LoginPage';

const FileInfoListPage = resolve => {
    require.ensure(['../components/FileInfoListPage'], () => {
        resolve(require('../components/FileInfoListPage'))
    })
}
const LoginPage = resolve => {
    require.ensure(['../components/LoginPage'], () => {
        resolve(require('../components/LoginPage'))
    })
}
const EditFileInfoPage = resolve => {
    require.ensure(['../components/EditFileInfoPage'], () => {
        resolve(require('../components/EditFileInfoPage'))
    })
}


Vue.use(VueRouter)

export const router = new VueRouter({
    routes : [
        {
            path : "/",
            name : "FileInfoList",
            components : {
                default : FileInfoListPage
            },
            beforeEnter: (to, from, next) =>
            {
                next();
            },
            beforeLeave: (to, from, next) =>
            {
                next();
            },
            children:[
                {
                    path:"",
                    components : {
                        default : EditFileInfoPage
                    },
                }
            ]
        },
        {
            path : "/callback",
            name : "Login",
            components : {
                default : LoginPage
            }
        },
        {
            path: "*",
            redirect:"/"
        }
    ],
    mode : 'history',
})
